<template>
  <div>
    <h1 class="grey--text">Elementi a disposizione</h1>
    <h3 class="grey--text">Scegli per selezionare</h3>
    <v-container fluid dense class="mt-5">
      <v-row>
        <v-col cols="3" v-for="(s, idx) of serviziDisponibili" :key="idx">
          <servizio :title="s.title" :subtitle="s.subtitle" :command="s.command" :page="s.page" @click="onServizioClick"></servizio> 
      </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import selfapi from '@/services/selfapi.js'
  import Servizio from './servizio.vue'
  const servizi = require('./serviziDisponibili.json')
/*   import GraficoValidati from './elementi/grafici/gr_validati.vue'
  import GraficoAsseverazione from './elementi/grafici/gr_asseverazione.vue'
 */
  export default {
    name: 'Dashboard',
    components: {
      Servizio
    },
    data: () => ({
      dashboard: null,
      entiValidati: [],
      enti:[],
      fornitoriValidati: [],
      fornitoriCompleti: [],
      serviziDisponibili: []
    }),
    async mounted() {
/*       this.dashboard = require('@/modelli/dashboard.json')
      const result = await selfapi.entiAsseverabili(true, false)
      let entiRaw = result.enti
      this.entiValidati = entiRaw.filter(x => _.every(x.servizi, x=> x.validato))
      
      let fornitori = _.uniq(_.flatten(this.entiValidati.map((x => x.servizi.map(x => x.fornitore)))))
      this.fornitoriValidati = fornitori.map(x => { return { fornitore: x, count: 0 }})
      for (let f of this.fornitoriValidati) {
        const enti = this.entiValidati.filter(x => x.servizi.some(x => x.fornitore === f.fornitore))
        f.count = enti ? enti.length : 0
      }
      fornitori = _.uniq(_.flatten(entiRaw.map((x => x.servizi && x.servizi.map(x => x.fornitore)))))
      this.fornitoriCompleti = fornitori.map(x => { return { fornitore: x, count: 0 }})
      for (let f of this.fornitoriCompleti) {
        const enti = entiRaw.filter(x => x.servizi.some(x => x.fornitore === f.fornitore))
        f.count = enti ? enti.length : 0
      } */

      for (let s of servizi) {
        this.serviziDisponibili.push(s)
      }
      
    },
    methods: {
      onServizioClick(page) {
        this.$router.push({path: page})
      }
    }
  }
</script>
