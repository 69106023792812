<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ title }}</span>
    </v-card-title>
    <v-card-text>
      {{ subtitle }}
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="onClick">{{ command }}</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    props: {
      title: String,
      subtitle: String,
      command: String,
      page: String
    },
    data: () => ({
      dashboard: null,
      entiValidati: [],
      enti:[],
      fornitoriValidati: [],
      fornitoriCompleti: []
    }),
    methods: {
      onClick() {
        this.$emit('click', this.page)
      }
    }
  }
</script>
